import HomeContentTile from "../../Components/Tile/homeContentTile";

const RecentProjects = () => {

    const tiles = [
        {id: 1, title: 'Saleo dashboard', subtitle: 'Mock e-commerce store sales dashboard that utilises ' +
                'React, Redux Toolkit, MUI and Styled components.', link: 'saleo-dashboard'},
        {id: 2, title: 'FLY Senga', subtitle: 'Full featured MVP that unites clothing and events into a single platform via the ' +
                'use of a recommendation engine.', link: 'fly-senga'},
    ];

    return (
        <div className={'inline-block my-8 px-8'}>
            <p className={'text-white text-xl sm:text-2xl font-bold '}>
                Check out my latest projects
            </p>
            <div className={'grid grid-rows-2 grid-cols-1 md:grid-rows-2 md:grid-cols-2 w-full my-8 gap-8'}>
                {
                    tiles.map(tile => <HomeContentTile
                        key={tile.id}
                        tile={tile}
                    />
                    )
                }
            </div>
        </div>
    );
}

export default RecentProjects;