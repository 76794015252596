import {useRef} from "react";
import {TypeAnimation} from "react-type-animation";

export const AnimatedTitle = ({onComplete, loaded}) => {

    const ref = useRef();
    const cursorClassName = 'custom-type-animation-cursor';

    const showCursorAnimation = (show) => {
        if (!ref.current){
            return;
        }

        const el = ref.current;
        if (show){
            el.classList.add(cursorClassName);
        } else {
            el.classList.remove(cursorClassName);
        }
    };

    if (loaded !== null){
        return (
            <div className={'block'}>
                <p className={'text-white text-4xl md:text-6xl lg:text-8xl'}>
                    Full stack engineer
                </p>
            </div>
        );
    }

    return (
        <>
            <div className={'block'}>
                <TypeAnimation
                    ref={ref}
                    cursor={false}
                    className={`${cursorClassName} text-white text-4xl md:text-6xl lg:text-8xl`}
                    speed={50}
                    sequence={[
                        'Engineer',
                        300,
                        'Programmer',
                        300,
                        'Collaborator',
                        300,
                        'Full stack engineer',
                        () => showCursorAnimation(false),
                        'Full stack engineer',
                        () => onComplete()
                    ]}
                    repeat={0}
                />
            </div>
            {/* Copy over the default typing styles. Also works with simple global css files or css modules */}
            <style global jsx>{`
              .custom-type-animation-cursor::after {
                content: '|';
                animation: cursor 1.1s infinite step-start;
              }  
              @keyframes cursor { 
                50% {
                  opacity: 0;
                } 
              }
            `}</style>
        </>
    );
}

export const AnimatedSubtitle = ({show, onComplete, loaded}) => {

    const ref = useRef();
    const cursorClassName = 'custom-type-animation-cursor';

    const showCursorAnimation = (show) => {
        if (!ref.current){
            return;
        }

        const el = ref.current;
        if (show){
            el.classList.add(cursorClassName);
        } else {
            el.classList.remove(cursorClassName);
        }
    }

    if (loaded !== null){
        return (
            <div className={'block my-8'}>
                <p className={'text-white text-sm sm:text-lg lg:text-xl'}>
                    Hi my name is Sohaib. I am a full stack developer who enjoys solving problems across the technology stack.
                </p>
            </div>
        );
    }

    return (
        <div className={'block my-8'}>
            {show
                ? <TypeAnimation
                    ref={ref}
                    cursor={false}
                    repeat={0}
                    speed={65}
                    className={`${cursorClassName} text-white text-sm sm:text-lg lg:text-xl`}
                    sequence={[
                        'Welcome to my site',
                        200,
                        'Hi my name is Sohaib. I am a full stack developer who enjoys solving problems across the technology stack.',
                        300,
                        () => showCursorAnimation(false),
                        () => onComplete()
                    ]}
                />
                : <div></div>
            }
        </div>
    );
}