import {StyledHeading, StyledParagraph} from "../../Components/text";
import {useRef} from "react";
import ContentsPage from "../../Components/Contents/contentsPage";
import ReactPlayer from "react-player";

const Overview = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Overview
            </StyledHeading>
            <StyledParagraph>
                The main idea behind FLY was to create a unified platform where users could purchase
                events and clothing under the same roof. The two distinct markets would be linked through the
                use of a recommender system. For instance, when a user viewed an event, they would receive clothing recommendations tailored to that event's dress code.
                Conversely, viewing a specific clothing item would generate suggestions for suitable events where the item could be worn.
            </StyledParagraph>
            <StyledParagraph>
                The platform was to take a mobile first approach and would primarily be available as an app on both the Android and
                iOS store. FLY clients were to have their own app that they could use to manage their store.
            </StyledParagraph>
        </div>
    );
};

const Features = () => {
    return <div className={'block'}>
        <StyledHeading>
            Features
        </StyledHeading>
        <StyledParagraph>
            The FLY platform consisted of two distinct applications: the FLY app for end-users,
            which offered clothing and event options, and the FLY for Business app, serving as either an inventory or
            event management tool for clients, depending on whether they were clothing brands or event promoters.
        </StyledParagraph>
        <div className={'block my-8'}>
            <StyledParagraph>
                <b>
                    FLY features:
                </b>
            </StyledParagraph>
            <ol className={'list-decimal list-inside'}>
                {
                    [
                        {
                            feature: 'Distinct UI for clothing and events',
                            description: 'Each section of the platform was designed with a unique UI to clearly differentiate between its two facets. ' +
                                'These elements would seamlessly merge when users explored a specific product or event.'
                        },
                        {
                            feature: 'Payment gateway integration',
                            description: 'The platform would need to handle payment processing, issue order confirmations, and allocate funds to vendors within our ecosystem.'
                        },
                        {
                            feature: 'Push notifications support',
                            description: 'Users would receive notifications for order confirmations and shipping updates.'
                        },
                        {
                            feature: 'In app QR tickets',
                            description: 'Purchased tickets would be available in the app to scan on event entry.'
                        },
                        {
                            feature: 'Clothing and events recommendations',
                            description: "The platform would generate recommendations based on user's personal and purchase data."
                        }
                    ].map((item, index) =>
                        <div key={index} className={'block'}>
                            <li

                                className={'text-white font-bold'}>
                                {item.feature}
                            </li>
                            <p className={'text-white my-4'}>
                                {item.description}
                            </p>
                        </div>

                    )
                }
            </ol>
        </div>
        <div className={'block my-8'}>
            <StyledParagraph>
                <b>
                    FLY for business features:
                </b>
            </StyledParagraph>
            <ol className={'list-decimal list-inside'}>
                {
                    [
                        {
                            feature: 'Payment and order notifications',
                            description: 'Both clients would receive notifications of payment whilst clothing clients ' +
                                'would also receive orders to fulfill.'
                        },
                        {
                            feature: 'QR scanner with live event statistics',
                            description: 'Events clients needed a built-in QR scanner that would allow them to scan for tickets.' +
                                ' A key issue here was preventing duplicate and used tickets from being used.'
                        },
                        {
                            feature: 'Inventory and ticket management UI',
                            description: 'Clothing and event clients could manage inventory, upload new items, ' +
                                'and update their profiles through distinct UIs within the same app.'
                        },
                        {
                            feature: 'Account withdrawals',
                            description: 'Clients would be able to withdraw funds from their account after a certain holding period had expired.'
                        }
                    ].map((item, index) =>
                        <div key={index} className={'block'}>
                            <li

                                className={'text-white font-bold'}>
                                {item.feature}
                            </li>
                            <p className={'text-white my-4'}>
                                {item.description}
                            </p>
                        </div>
                    )
                }
            </ol>
        </div>
    </div>;
};

const Frontend = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Frontend
            </StyledHeading>
            <StyledParagraph>
                The decision to use Flutter as the primary app development framework
                over react native was primarily due to the large number of widgets that
                came with Flutter, the popularity of the framework on Stack overflow and
                the strength of the community surrounding Flutter. Additionally,
                Flutter offers <a className={'text-white underline'} href={'https://api.flutter.dev/flutter/widgets/ListWheelScrollView-class.html'}>unique and innovative</a> widgets that can enhance the user interface in novel ways.
            </StyledParagraph>
            <StyledParagraph>
                One of the benefits of Flutter is the reduction in development time due to the framework's cross-platform capabilities. In addition to this, Flutter applications follow a
                straightforward app architecture that speeds up development time.
                The architecture pioneered by <a className={'text-white underline'} href={'https://verygood.ventures/blog/very-good-flutter-architecture'}>Very Good Ventures</a> breaks an application down into 3 layers
                where each layer has a distinct responsibility. The UI layer is solely responsible for presenting data and interactive widgets, the business logic layer contains the app's core functionality
                whilst the repository layer is responsible for making network calls and passing data to the business logic layer.
            </StyledParagraph>
            <StyledParagraph>
                Overall, Flutter is a solid multiplatform framework and the dart language is easy to use given its similarities to JavaScript. The <a className={'text-white underline'} href={'https://bloclibrary.dev/#/'}> Flutter BLOC</a> package
                abstracts away much of the complexity when dealing with concurrency. Having built native Android apps using Kotlin and coroutines, Flutter and its ecosystem streamlines the process,
                letting developers concentrate on building app features.
            </StyledParagraph>
        </div>
    );
};

const Backend = () => {
   return (
       <div className={'block'}>
           <StyledHeading>
               Backend
           </StyledHeading>
           <StyledParagraph>
               The backend comprised of a monolithic Node.js server employing a <a className={'text-white underline'} href={'https://martinfowler.com/bliki/CQRS.html'}>CQRS</a>  architecture, which isolates the read and write models
               into distinct data stores. This choice was driven by the requirements of our recommender system, which performed intricate joins and queries, necessitating an SQL-based datastore.
               In contrast, the read model primarily fetched straightforward documents related to clothing and events, along with related inventory information. As a result, we opted for a simpler NoSQL solution to handle these information requests.
           </StyledParagraph>
           <StyledParagraph>
               I employed <a className={'text-white underline'} href={'https://fastify.dev'}>Fastify</a> as the server framework and designed the API according to RESTful principles. HTTP verbs described the request handlers, while nouns served as the endpoints.
               Most of the endpoints adhered to a CRUD-style approach, catering to straightforward information requests through stateless services. The backend's most intricate module was order processing; given the platform's multi-vendor nature, it was critical to ensure accurate fund transfers to vendors while maintaining testable code.
               To manage this complexity, I adopted a Domain-Driven Design strategy, which facilitated problem decomposition and prioritized system design prior to implementation.
           </StyledParagraph>
       </div>
   );
};

const Recommendations = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Recommender system
            </StyledHeading>
            <StyledParagraph>
                The recommender system operated as an independent service, housed on its own server within our private VPC.
                Utilizing a rules-based engine, the system employed a map-reduce methodology for generating item recommendations.
                We opted for Python as the primary language, capitalizing on its robust data processing libraries, and chose FlaskAPI for the server framework.
                The main server interfaced with this recommender service, retrieving and then relaying suggestions to our user base.
            </StyledParagraph>
            <StyledParagraph>
                We opted for a rules-based engine because we lacked the user purchase data needed for a machine learning approach.
                To offset this data deficiency, we assumed early adopters would be more willing to share personal preferences during signup.
                This information then fueled the rules-based recommendation engine.
            </StyledParagraph>
        </div>
    );
};

const Integrations = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                API Integrations
            </StyledHeading>
            <StyledParagraph>
                Firebase facilitated authentication for both the FLY user app and the FLY for Business app, with the former offering support for anonymous sign-ups.
                Device notifications were managed through Firebase's notification service, which was integrated with AWS SNS.
                Additionally, we employed deep linking to accommodate Stripe Connect's requirements.
                Vendors were directed to Stripe's website to input business-related details; upon completion, deep links enabled seamless redirection back to our app.
            </StyledParagraph>
            <StyledParagraph>
                Email functionality was handled by MailChimp transactional and allowed
                the monolith to send out emails. The strength of the javascript package ecosystem came through here,
                as I was able to use the handlebars templating language to inject dynamic content into emails.
            </StyledParagraph>
            <StyledParagraph>
                Stripe's Connect API provided the much needed payments infrastructure for the application.
                Their extensive support for marketplaces combined with precise documentation on their site
                allowed for a smooth integration into our system. Payments would typically contain items from different vendors
                and Stripe provided functionality that would allow FLY to break up the payment,
                take a commission charge and transfer each client their appropriate share of the payment.
            </StyledParagraph>
        </div>
    );
};

const Deployment = () => {
   return (
       <div className={'block'}>
           <StyledHeading>
               Cloud deployment
           </StyledHeading>
           <StyledParagraph>
               The server was containerized using Docker and deployed on AWS Elastic Beanstalk.
               Leveraging AWS's robust VPC capabilities, I easily established a peer connection with MongoDB.
               Both MemoryDB and RDS were integrated within the same VPC, granting the web server access to managed instances of Postgres and Redis.
               AWS SNS and SQS were employed to facilitate push notifications, while S3 and CloudFront were used for efficient image delivery to mobile applications.
               AWS Route 53 served not only to alias the web server address but also to facilitate Mailchimp integration through its hosted zones feature.
           </StyledParagraph>
       </div>
   );
};

const Demos = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Demos
            </StyledHeading>
            <div className={'block bg-black my-4'}>
                <div className={'inline-block my-8'}>
                    <ReactPlayer
                        url={'https://d3pm7ftziy677a.cloudfront.net/FlyEdited.mp4'}
                        playing={true}
                        loop={true}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
                <div className={'inline-block my-8'}>
                    <ReactPlayer
                        url={'https://d3pm7ftziy677a.cloudfront.net/FlyEventsClient.mp4'}
                        playing={true}
                        loop={true}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
                <div className={'inline-block my-8'}>
                    <ReactPlayer
                        url={'https://d3pm7ftziy677a.cloudfront.net/FlyClothingClientEdit.mp4'}
                        playing={true}
                        loop={true}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
            </div>
        </div>
    );
}

const FlySenga = () => {

    const sections = [
        {id: 'overview', text: 'Overview', link: '#overview'},
        {id: 'features', text: 'Key features', link: '#features'},
        {id: 'frontend', text: 'Front end', link: '#frontend'},
        {id: 'backend', text: 'Back end', link: '#backend'},
        {id: 'recommender', text: 'Recommender system', link: '#recommender'},
        {id: 'integrations', text: 'API Integrations', link: '#integrations'},
        {id: 'deployment', text: 'Deployment', link: '#deployment'},
        {id: 'demos', text: 'Demos', link: '#demos'}
    ];

    const overviewRef = useRef(null);
    const featuresRef = useRef(null);
    const frontendRef = useRef(null);
    const backendRef = useRef(null);
    const recommenderRef = useRef(null);
    const integrationRef = useRef(null);
    const deploymentRef = useRef(null);
    const demosRef = useRef(null);

    const refs = [overviewRef, featuresRef, frontendRef, backendRef, recommenderRef, integrationRef, deploymentRef, demosRef];

    return(
        <ContentsPage
            pageTitle={'FLY Senga'}
            pageSubtitle={'Clothing and events marketplace'}
            pageDate={'18 Aug 2023'}
            pageSections={sections}
            refs={refs}
        >
            <Overview/>
            <Features/>
            <Frontend/>
            <Backend/>
            <Recommendations/>
            <Integrations/>
            <Deployment/>
            <Demos/>
        </ContentsPage>
    );
}

export default FlySenga;