import {useRef} from "react";
import ContentsPage from "../../Components/Contents/contentsPage";

const Overview = () => {
    return (
        <div className={'block'}>

            <p className={'text-white text-2xl sm:text-4xl'}>
                Overview
            </p>
            <p className={'text-white text-lg my-4'}>
                An e-commerce analytics dashboard with a fixed layout to keep charts legible across all screen sizes.
                The application offers responsive design elements, allowing mobile users to navigate the dashboard via a drawer instead of a pane.
                Users have the capability to filter data tables using available criteria and can further refine their search as needed.
                The dashboard is solely a UI demo without network calls.
            </p>
            <p className={'text-white text-lg my-4'}>
                The application uses Tailwind for the majority of the components, Material UI is used for complex components including the filter dialogs,
                data tables and time pickers. The application uses Redux to manage the state in the data tables, filtering logic is handled by the slice reducers from
                Redux Toolkit.
            </p>
            <p className={'text-white text-lg my-4'}>
                Click <a href={'https://saleo-e-commerce-analytics-dashboard-6rc7e64ti-sibm02021.vercel.app'} className={'text-white underline'}>here</a> to view the full dashboard.
            </p>
        </div>
    );
}


const SaleoDashboard = () => {

    const sections = [
        {id: 'overview', text: 'Overview', link: '#overview'},
    ];

    const overviewRef = useRef(null);

    const refs = [overviewRef];

    return (
        <ContentsPage
            pageTitle={'Saleo dashboard'}
            pageSubtitle={'Mock e-commerce sales and analytics dashboard'}
            pageDate={'20 Sep 2023'}
            pageSections={sections}
            refs={refs}
        >
            <Overview/>
        </ContentsPage>
    );
}
export default SaleoDashboard;