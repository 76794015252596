import {useRef, useState} from "react";
import HomeContentTile from "../../Components/Tile/homeContentTile";
import {AnimatedSubtitle, AnimatedTitle} from "./animatedText";
import SouthIcon from '@mui/icons-material/South';

const Home = () => {

    const loaded = window.sessionStorage.getItem('loaded');

    const myProjectsRef = useRef(null);
    const [titleLoaded, setTitleLoaded] = useState(false);
    const [subtitleLoaded, setSubtitleLoaded] = useState(false);

    const handleTitleLoaded = () => setTitleLoaded(true);
    const handleSubtitleLoaded = () => {
        setSubtitleLoaded(true);
        window.sessionStorage.setItem('loaded', 'true');
    };

    const projectTiles = [
        {id: 1, title: 'Fly Senga', subtitle: 'A complete MVP with a Flutter front end and Node.js backend. The main ' +
                'idea was to host events and clothing on the same platform through the use of a recommender system.', link: 'projects/fly-senga'},
        {id: 2, title: 'Saleo dashboard', subtitle: 'A mock sales analytics dashboard that uses React, Redux-Toolkit, MUI and Tailwind. The app' +
                ' has a fixed layout which allows the charts to scale across all device sizes and maintain visual consistency.', link: 'projects/saleo-dashboard'},
        {id: 3, title: 'Lessons from FLY', subtitle: 'Lessons as a founding engineer.', link: 'thoughts/lessons-from-fly'},
        {id: 4, title: 'The 8pt grid', subtitle: "I sensed a gap in my user interface development—my designs lacked a cohesive structure." +
                " That's when I discovered the 8pt grid system, a revelation that elevated my design skills.", link: 'thoughts/8-pt-grid'},
        {id: 5, title: 'Fastify and JWT', subtitle:'Protecting routes and services using JWT with the Fastify framework.', link: 'thoughts/protecting-endpoints'}
    ];

    const handleClick = () =>
        myProjectsRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});

    return (
        <>
            <section id={'home'}>
                <div className={'flex flex-col justify-end items-center w-full min-h-screen px-8'}>
                    <AnimatedTitle
                        onComplete={handleTitleLoaded}
                        loaded={loaded}
                    />
                    <AnimatedSubtitle
                        show={titleLoaded}
                        onComplete={handleSubtitleLoaded}
                        loaded={loaded}
                    />
                    <div
                        onClick={handleClick}
                        className={'flex flex-col justify-center items-center my-16 p-4 sm:p-8 rounded-full border-white border-2 hover:opacity-50 hover:scale-110 cursor-pointer'}>
                        <SouthIcon
                            fontSize={'large'}
                            sx={{
                                color: 'white'}}
                        />
                    </div>
                </div>
            </section>
            <section id={'projects'}>
                <div
                    ref={myProjectsRef}
                    className={'flex flex-col w-full min-h-screen px-8 py-16 sm:py-24'}>
                    <div className={'block my-8 py-4 px-8'}>
                        <p className={'text-white text-3xl font-bold'}>
                            Recent activity
                        </p>
                    </div>
                    <div className={'grid grid-rows-4 grid-cols-1 md:grid-rows-2 md:grid-cols-2 w-full my-4 gap-8'}>
                        {
                            projectTiles.map(tile => <HomeContentTile
                                key={tile.id}
                                tile={tile}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
