import {useMediaQuery} from "react-responsive";
import NavMenu from "../Menu/navMenu";
import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
const background = 'bg-gradient-to-br from-gray-900 to-black';

const NavigationBar = () => {

    const [scrolledY, setScrolledY] = useState(0);
    const [selectedLink, setSelectedLink] = useState('Home');

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 5){
                setScrolledY(window.scrollY);
            } else {
                setScrolledY(0);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }

    }, [scrolledY]);

    const links = [
        {id: 'about', text: 'About', link: 'about'},
        {id: 'projects', text: 'Projects', link: 'projects'},
        {id: 'thoughts', text: 'Thoughts', link: 'thoughts'}
    ];

    const handleSelectedMobileMenu = (id) => {
        links.forEach(link => {
            if (link.id === id){
                setSelectedLink(link.text);
            }
        })
    }

    return (
        <div className={`fixed flex flex-row justify-between items-center w-full p-8 ${scrolledY ? background : 'bg-transparent'} z-50`}>
            <NavLink
                to={'/'}
                className={'text-white font-bold hover:underline'}
                onClick={() => setSelectedLink('Home')}
            >
                Sohaib's Blog
            </NavLink>
            {isMobile
                ? <div className={'flex flex-col'}>
                    <NavMenu
                        links={links}
                        selected={selectedLink}
                        onSelected={handleSelectedMobileMenu}
                    />
                </div>
                : <div className={'flex flex-row'}>
                    {
                        links.map(link =>
                            <div
                                key={link.id}
                                className={'mx-8'}>
                                <NavLink
                                    to={link.link}
                                    onClick={() => {
                                        setSelectedLink(link.text)
                                    }}
                                    className={`text-white text-lg ${selectedLink === link.text ? 'underline' : 'no-underline'}`}
                                >
                                    {link.text}
                                </NavLink>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
}

export default NavigationBar;