import HomeContentTile from "../../Components/Tile/homeContentTile";

const RecentThoughts = () => {

    const tiles = [
        {id: 2, title: 'Fastify and JWT', subtitle: 'Protecting routes and services using JWT with the Fastify framework.', link: 'protecting-endpoints'},
        {id: 3, title: 'The 8pt grid', subtitle: "I sensed a gap in my user interface development—my designs lacked a cohesive structure." +
                " That's when I discovered the 8pt grid system, a revelation that elevated my design skills.", link: '8-pt-grid'},
        {id: 4, title: 'Fly zero to one', subtitle: 'Lessons that I learned as a founding engineer.', link: 'lessons-from-fly'},
    ];

    return (
        <div className={'inline-block my-8 px-8'}>
            <p className={'text-white text-xl sm:text-2xl font-bold '}>
                Learning and development
            </p>
            <div className={'grid grid-rows-2 grid-cols-1 md:grid-rows-2 md:grid-cols-2 w-full my-8 gap-8'}>
                {
                    tiles.map(tile => <HomeContentTile
                        key={tile.id}
                        tile={tile}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default RecentThoughts;