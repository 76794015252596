import NavigationBar from "./Components/Navigation/navigationBar";
import {Outlet, ScrollRestoration} from "react-router-dom";
import {Analytics} from "@vercel/analytics/react";

const Root = () => {
    return (
        <div className={'flex flex-col w-full bg-gradient-to-br from-gray-800 to-black'}>
            <NavigationBar/>
            <ScrollRestoration />
            <Analytics/>
            <Outlet/>
        </div>
    );
}

export default Root;