import {PageWrapper} from "../containers";
import PageTitleTile from "../Tile/pageTitleTile";
import React, {forwardRef, useEffect, useState} from "react";
import ContentsSection from "./contentsSection";

const ContentSection = forwardRef(function ContentSection(props, ref){
    return (
        <div id={props.section.id} className={'block my-4 py-24'} ref={ref}>
            {
                props.children
            }
        </div>
    );
});

const ContentsPage = ({pageTitle, pageSubtitle, pageDate, pageSections, refs,  children}) => {

    const sections = React.Children.toArray(children);
    const [visibleSection, setVisibleSection] = useState('');

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.id;
                    if (entry.isIntersecting){
                        setVisibleSection(id);
                    }
                });
            },
            {
                threshold: 0.5
            }
        );

        refs.forEach((divRef) => {
            observer.observe(divRef.current);
        });

        return () => {
            refs.forEach((divRef) => {
                if (divRef.current){
                    observer.unobserve(divRef.current);
                }
            });
        }

    }, [refs]);

    return (
        <PageWrapper>
            <div className={'block px-8'}>
                <PageTitleTile title={pageTitle} subtitle={pageSubtitle} date={[pageDate]}/>
                <ContentsSection
                    sections={pageSections}
                    currentSection={visibleSection}
                >
                    {
                        pageSections.map((section, index) => {
                            const ref = refs[index];
                            const child = sections[index];
                            return (
                                <ContentSection
                                    key={index}
                                    ref={ref}
                                    section={section}>
                                    {child}
                                </ContentSection>
                            );
                        })
                    }
                </ContentsSection>
            </div>
        </PageWrapper>
    );

}

export default ContentsPage;