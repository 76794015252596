
const ContentsTable = ({sections, currentSection}) => {

    return (
        <div className={'inline-block my-16 px-8 lg:sticky lg:top-32'}>
            <p className={'text-white text-sm'}>
                Contents
            </p>
            {
                sections.map(section =>
                    <div key={section.id}
                        className={'block py-4'}>
                        <a href={section.link}
                           className={`text-white ${section.id === currentSection ? 'font-bold' : 'font-normal'}`}
                        >
                            {section.text}
                        </a>
                    </div>
                )
            }
        </div>
    );
};

const ContentsSection = ({sections, currentSection,  children}) => {
    return (
        <div className={'flex flex-col lg:flex-row-reverse lg:justify-around'}>
            <div className={'block'}>
                <ContentsTable currentSection={currentSection} sections={sections}/>
            </div>
            <div className={'flex flex-col w-full lg:w-7/12'}>
                {children}
            </div>
        </div>
    );
}

export default ContentsSection;