const PageTitleTile = ({title, subtitle, date}) =>
    <div className={'flex flex-col w-full lg:w-8/12 py-4 px-8 bg-gray-800 rounded-2xl shadow-lg shadow-gray-900'}>
        <p className={'text-white text-3xl md:text-5xl font-bold my-4'}>
            {title}
        </p>
        <p className={'text-gray-400 text-lg my-4'}>
            {subtitle}
        </p>
        <div className={'flex flex-col items-end py-4 px-8'}>
            <p className={'text-gray-400 text-lg'}>
                Date
            </p>
            <p className={'text-white text-lg'}>
                {date}
            </p>
        </div>
    </div>;

export default PageTitleTile;