import {StyledParagraph} from "../../Components/text";
import {Link} from "react-router-dom";
import {PageWrapper} from "../../Components/containers";
import PageTitleTile from "../../Components/Tile/pageTitleTile";

const About = () => {
    return (
        <PageWrapper>
            <div className={'inline-block w-full p-8'}>
                <div className={'flex flex-row justify-between w-full py-4 px-8 bg-gray-800 rounded-2xl shadow-lg shadow-gray-900'}>
                    <h1 className={'text-white text-3xl md:text-5xl font-bold my-4'}>
                        About me
                    </h1>
                    <div className={'block h-24 w-24 my-8'}>
                        <img
                            src={'https://d3pm7ftziy677a.cloudfront.net/self.jpg'}
                            alt={'me'}
                            className={'w-full h-full object-cover rounded-full'}
                        />
                </div>

                </div>
                <div className={'block my-8'}>
                    <StyledParagraph>
                        I'm a Full Stack Engineer proficient in front-end technologies like React and Flutter,
                        back-end frameworks including Node.js and Flask, and cloud configuration via AWS.
                    </StyledParagraph>
                    <StyledParagraph>
                        I enjoy the comprehensive nature of full-stack engineering, which allows me to engage in every facet of a system's design and development.
                        I thrive on crafting intuitive user interfaces and tackling complex technical challenges through my expertise in algorithms and machine learning.
                    </StyledParagraph>
                    <h2 className={'text-white font-bold text-2xl sm:text-3xl lg:text-4xl'}>
                        Background
                    </h2>
                    <StyledParagraph>
                        With a background in finance, I transitioned to software engineering when I came across the field of Machine Learning. Inspired by the
                        world of neural networks and deep learning, I tried to implement some ideas that I had in mind. Through this process, I taught myself how to code and gained an
                        understanding of how machine learning models were trained and implemented.
                    </StyledParagraph>
                    <h2 className={'text-white font-bold text-2xl sm:text-3xl lg:text-4xl'}>
                        Recent work
                    </h2>
                    <StyledParagraph>
                        I recently served as a founding engineer and CTO of a <a className={'text-white underline'} href={'http://www.flysenga.com'}>startup</a> that intertwined clothing and events through the use of a recommendation system. I helped take an idea
                        from paper to a functional MVP with a frontend in Flutter and backend in Node.js. This process involved applying for patent protection, collaborating with UX/UI designers and other engineers to build a complex
                        MVP. Read more about my experience <Link className={'text-white underline'} to={'/thoughts/lessons-from-fly'}>here.</Link>
                    </StyledParagraph>
                    <StyledParagraph>
                        With a unique background, I excel in adapting to diverse challenges and eagerly confront technical complexities.
                        Skilled at quickly mastering new languages and frameworks, I appreciate all facets of engineering (even refactoring).
                        Leveraging my finance experience, I bridge the technology and business realms through inclusive language that
                        fosters understanding between the two paradigms.
                    </StyledParagraph>
                </div>
            </div>
        </PageWrapper>
    );
}

export default About;