import {useRef} from "react";
import ContentsPage from "../../Components/Contents/contentsPage";
import {StyledHeading, StyledParagraph} from "../../Components/text";

const Overview = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Overview
            </StyledHeading>
            <StyledParagraph>
                I recently completed a backend engineering challenge that required implementing routes in Node.js that were protected by
                JSON Web Tokens (JWT for short). The challenge was simple and required the creation of routes with differing levels of access.
                Some routes were open to anyone whilst others were protected and required a user with the correct permissions to be able to access services and resources.
            </StyledParagraph>
            <StyledParagraph>
                To solve the problem I chose my preferred HTTP server framework called Fastify and a useful package developed by the Fastify
                team called <a className={'text-white font-bold'} href={'https://github.com/fastify/fastify-jwt'}>Fastify-JWT</a>.
                Before going into the implementation of JWT in Fastify, it is useful to gain a basic understanding of JSON Web Tokens and why we
                need them when creating protected routes and services.
            </StyledParagraph>
        </div>
    );
}

const JWT = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                JSON Web Tokens
            </StyledHeading>
            <StyledParagraph>
                JWT short for JSON Web Token is an <i className={'font-bold'}>open standard that defines a compact and self-contained way for securely transmitting
                information between parties</i> (Source: <a className={'font-bold'} href={'https://jwt.io/introduction'}>JWT.io</a>). The most common use case of JWT is implementing authorisation
                where a server allows access to users by making sure they are authorised to access the underlying resource. Requests made to a server should typically contain
                an <b>Authorisation</b> header that has a <b>Bearer token</b> which in this case is a JWT.
            </StyledParagraph>
            <div className={'block py-8 px-4 bg-gray-800 rounded-2xl'}>
                <p className={'text-white font-bold'}>
                    Header format
                </p>
                <p className={'text-white font-bold my-4'}>
                    Authorization: Bearer JWT
                </p>
                <p className={'text-white text-sm'}>
                    Remember to keep a single space between the Bearer text and the token itself.
                </p>
            </div>
            <StyledParagraph>
                JSON Web Tokens are comprised of three parts that come together to form the security protocol. The first part is called the <b>header</b> and contains information about
                the algorithm used to sign the token and the type of token which is almost always set to JWT. The second part is the <b>payload</b> and is the most important part of the JWT as it contains
                claims about an entity. Whilst you can configure the algorithm used in the header, <b className={'italic'}>the payload is the only part of the token that you are able to fully customise</b> as
                you can add your own claims to the payload that can then be used by server. The third part of the JWT is the <b>signature</b> which uses the information in the header,
                payload and secret to sign and create the final part of the token. This signature is used to verify that the message wasn't changed.
            </StyledParagraph>
            <StyledParagraph>
                The payload can be broken down into 3 types of claims which represent different pieces of information, a claim can either be a <b>registered</b> claim, a <b>public</b> claim or a <b>private</b> claim.
                The two claims that are of most interest are the registered claims and private claims.
                <ol>
                    <li className={'text-white font-bold my-4'}>
                        Registered claims
                    </li>
                    <p className={'text-white'}>
                        Registered claims are akin to meta-data and comprise of a predefined set of claims that provide extra information about your payload. The most common claims that you can
                        add are <b>iss:</b> (issuer) i.e. the issuer of the token, <b>sub:</b> (subject) i.e. the subject of the token and <b>exp</b>  (expiration time) which displays the length of time
                        the current JWT will be valid for. There are other claims that you can add that are classified as Registered claims.
                    </p>
                    <li className={'text-white font-bold my-4'}>
                        Private claims
                    </li>
                    <p className={'text-white'}>
                        Private claims are domain specific claims. The server will typically consume the data contained in these claims.
                        An example could be a UUID that represents a specific user in your application, you would add a key called uid: and set its value to the current user. Every request from
                        now until a specified expiry period would use the uid claim to access resources and services for that specific user.
                    </p>
                </ol>
            </StyledParagraph>
        </div>
    );
}

const Fasitfy = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Fastify and implementing JWT
            </StyledHeading>
            <StyledParagraph>
                To implement protected routes in your application, you will
                need <a className={'text-white underline'} href={'https://fastify.dev'}>Fastify</a> and <a className={'text-white underline'} href={'https://github.com/fastify/fastify-jwt'}>Fastify-JWT</a>. The
                Fastify-JWT package provides extra methods that will add JWT functionality to your application. The first step is to import the JWT plugin into your index.ts file and register
                it with your fastify instance. The package will decorate your server instance and add a few methods that you will use to perform JWT signing and validation.
            </StyledParagraph>
            <StyledParagraph>
                <b className={'italic'}>
                    The secret should be unique to your server and is used as part of the signing process.
                </b>
            </StyledParagraph>
            <div className={'grid grid-cols-1 w-full py-8 px-4 my-4 bg-gray-800 rounded-2xl gap-2 overflow-x-auto'}>
                <code className={'text-white'}>
                    <span className={'text-purple-600 mx-2'}>
                        import
                    </span>
                    Fastify
                    <span className={'text-purple-600 mx-2'}>
                        from
                    </span>
                    <span className={'text-green-700'}>
                        "fastify"
                    </span>
                    ;
                </code>
                <code className={'text-blue-500'}>
                    <span className={'text-purple-600 mx-2'}>
                        import
                    </span>
                    jwt
                    <span className={'text-purple-600 mx-2'}>
                        from
                    </span>
                    <span className={'text-green-700'}>
                        "@fastify/jwt"
                    </span>
                    <span className={'text-white'}>
                        ;
                    </span>
                </code>
                <p></p>
                <code className={'text-white whitespace-pre'}>
                    <span className={'text-purple-600 mx-2'}>
                        const
                    </span>
                    server
                    =
                    <span className={'text-blue-500 ml-2'}>
                        Fastify
                    </span>
                    {`(\u007B\n    `}
                    <span className={'mr-2'}>
                        logger:
                    </span>
                    <span className={'text-blue-500'}>
                        true
                    </span>
                    {`\n\u007D);`}
                </code>
                <code className={'text-white whitespace-pre'}>
                    <span className={'ml-2'}>
                        server.
                    </span>
                    <span className={'text-violet-400'}>
                        register
                    </span>
                    (
                    <span className={'text-blue-500 mx-2'}>
                        jwt
                    </span>
                    ,
                    {`(\u007B\n    `}
                    <span className={'mr-2'}>
                        secret:
                    </span>
                    <span className={'text-green-700'}>
                        "Your_secret_here"
                    </span>
                    {`\n\u007D);`}
                </code>
            </div>
            <StyledParagraph>
                Once you have registered the JWT plugin, your fastify instance will have the sign(), verify() and decode() methods
                available. <b className={'italic'}> We will use the sign() method inside a route handler to sign a payload containing claims about our user.</b> The
                server would typically respond to the request by sending the signed token back to the consumer and subsequent requests would then contain the Authorisation header with the token.
                It is important to note
                that claims are traditionally three characters to keep the size of the header small whilst providing enough characters
                so that we can confidently reason about what the claim is.
            </StyledParagraph>
            <div className={'grid grid-cols-1 w-full py-8 px-4 my-4 bg-gray-800 rounded-2xl gap-2 overflow-x-auto'}>
                <code className={'text-white whitespace-pre'}>
                    <span className={'text-purple-600 mx-2'}>
                        const
                    </span>
                    token
                    =
                    fastify.
                    <span className={'text-blue-500'}>
                        jwt
                    </span>
                    .
                    <span className={'text-violet-400'}>
                        sign
                    </span>
                    {`(\u007B\n    `}
                    <span className={'mr-2'}>
                        uid:
                    </span>
                    <span className={'text-green-700'}>
                        "#123131mmadsa"
                    </span>
                    {`\n\u007D);`}
                </code>
            </div>
            <StyledParagraph>
                In addition to having the new methods on our server instance, we will also have .verify() and .decode() on
                our request object and .sign() on our response object. The <b>decoded</b> object will contain the contents of our
                payload so we could use the uid to perform database operations and fetch the user's profile information.
            </StyledParagraph>
            <div className={'grid grid-cols-1 my-4 py-8 px-4 bg-gray-800 rounded-2xl gap-2 overflow-x-auto'}>
                <code className={'text-white whitespace-pre'}>
                    fastify.
                    <span className={'text-purple-600'}>
                        post
                    </span>
                    (
                    <span className={'text-green-700'}>
                        '/api/profile'
                    </span>
                    ,
                    (request: FastifyRequest, reply: FastifyReply) =>
                    {`(\u007B\n    `}
                    <code className={'text-white whitespace-pre'}>
                        request.
                        <span className={'text-blue-500'}>
                            jwtVerify
                        </span>
                        ((err, decoded)) =>
                        {`(\u007B\n        `}
                        <code className={'text-white whitespace-pre'}>
                            <span className={'text-orange-400 mr-2'}>
                                if
                            </span>
                            (err)
                            {`\u007B\n            `}
                            <code className={'text-white whitespace-pre'}>
                                response.
                                <span className={'text-blue-500'}>
                                    code
                                </span>
                                (400);
                            </code>
                            {`\n        \u007D;`}
                            {`\n        Successful validation - Access decoded.uid and fetch profile.\n`}
                        </code>
                        {`\n    \u007D);`}
                    </code>
                    {`\n\u007D);`}
                </code>
            </div>
            <StyledParagraph>
                The route is now protected
                which means any requests made to the endpoint without a valid JWT will respond with a bad request error.
                For the full example <a href={'https://gist.github.com/Sibm02021/c011039f805a6ff90068cf005e4f93e9'} className={'text-white font-bold'}>click here.</a>
            </StyledParagraph>
            <p className={'text-white text-lg font-bold my-8'}>
                References and links
                <ul>
                    <li>
                        <a href={'https://jwt.io/introduction'} className={'text-white underline font-normal'}>
                            Introduction to JWT - JWT.IO
                        </a>
                    </li>
                    <li>
                        <a href={'https://auth0.com/resources/ebooks/jwt-handbook/thankyou'} className={'text-white underline font-normal'}>
                            JWT Handbook by Sebastián Peyrott
                        </a>
                    </li>
                </ul>
            </p>
        </div>
    );
}


const ProtectingEndpoints = () => {

    const sections = [
        {id: 'overview', text: 'Overview', link: '#overview'},
        {id: 'jwt', text: 'JSON Web Tokens', link: '#jwt'},
        {id: 'implementation', text: 'Fastify and implementing JWT', link: '#implementation'}
    ];

    const jwtRef = useRef(null);
    const fastifyRef = useRef(null);
    const implementation = useRef(null);

    const refs = [jwtRef, fastifyRef, implementation];

    return (
        <ContentsPage
            pageTitle={'Fastify and JWT'}
            pageSubtitle={'Protecting endpoints using JWT'}
            pageDate={'09 Oct 2023'}
            pageSections={sections}
            refs={refs}
        >
            <Overview/>
            <JWT/>
            <Fasitfy/>
        </ContentsPage>
    );

}

export default ProtectingEndpoints;