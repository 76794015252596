import ContentsPage from "../../Components/Contents/contentsPage";
import {useRef} from "react";
import {StyledParagraph} from "../../Components/text";

const DisciplinedDesign = () => {
    return (
        <div className={'block'}>
            <p className={'text-white text-2xl sm:text-4xl'}>
                Disciplined design
            </p>
            <p className={'text-white text-lg my-4'}>
                I recently collaborated with a UX designer to improve a UI that I had created. The aim of the project was
                to change the colour scheme of the design and give the interface a more consistent look and feel. I handed over the existing
                designs and was surprised by the massive improvements that the designer was able to achieve. The spacing and sizing of components
                were much more consistent and uniform, providing a cleaner UI that was easier on the eye.
            </p>
            <p className={'text-white text-lg my-4'}>
                Since embarking on my software engineering journey, I have noticed that a structured approach is the key to creating exponentially better solutions and that a lack of
                structure can lead to a messy solution that can be difficult to extend or reproduce. The lack of consistency in my designs suggested that I needed some sort of
                system that I could use to guide my design decisions. For example, when adding margins or padding to a div, I would often make decisions local to that component and use trial
                and error until I thought the design looked good. This experimental approach would ultimately result in pages that were visually inconsistent as they were made up of components
                that had different spacing and sizing. I needed a way to think about my designs globally so that the different sections of the page when brought together would follow the same
                styling guidelines. It was at this point when I came across the 8-pt grid system.
            </p>
            <p className={'text-white text-lg my-4'}>
                Although simple, the system provided a disciplined approach to making design decisions.
            </p>
        </div>
    );
};

const TheSystem = () => {
    return (
        <div className={'block'} >
            <p className={'text-white text-2xl sm:text-4xl'}>
                The system
            </p>
            <StyledParagraph>
                The 8-pt system is pretty simple and suggests that all padding, margins, dimensions and borders for a component should be a multiple of 8pt (8px). This is due to the nature of
                most screen sizes being multiples of 8 on at least one axis.
            </StyledParagraph>
            <StyledParagraph>
                It reduces the number of decisions that you make when choosing a value for a div's padding or margin. You have creative freedom in that you can choose which multiples you want to use,
                however, some articles suggest that this causes inconsistency due to the number of intervals available. For example in Tailwind, you have the choice of moving up from 8 to 96pts which is
                around 12 different values that you could use. To combat this and reduce the number of choices, you can use a logarithmic scale where you start from 2/4 pt as the smallest,
                and double the value from 8 onwards (16, 32, 64 and 128). The total number of choices has been reduced to 7 including the half-point values. The smaller end of this scale will typically be used as padding and margin values
                whereas the larger sizes can be used as constraints for boxes.
            </StyledParagraph>
            <StyledParagraph>
                The overall result is that your designs are much more consistent.
            </StyledParagraph>
            <p className={'text-white text-lg my-4 font-bold'}>
                References
            </p>
            <ul>
                <li>
                    <a className={'text-white my-4 underline'} href={'https://spec.fm/specifics/8-pt-grid'}>
                        The 8-point grid definitive guide
                    </a>
                </li>
                <li>
                    <a className={'text-white my-4 underline'} href={'https://medium.com/built-to-adapt/intro-to-the-8-point-grid-system-d2573cde8632'}>
                        Intro the 8pt grid system (Elliot Dahl)
                    </a>
                </li>
            </ul>
        </div>
    );
};

const EightPtGrid = () => {

    const sections = [
        {id: 'design', text: 'Disciplined design', link: '#design'},
        {id: 'system', text: 'The system', link: '#system'},
    ];

    const designRef = useRef(null);
    const systemRef = useRef(null);
    const refs = [designRef, systemRef];

    return (
        <ContentsPage
            pageTitle={'The 8-pt grid system'}
            pageSubtitle={'Improving visual consistency'}
            pageDate={'22 Sep 2023'}
            pageSections={sections}
            refs={refs}
        >
            <DisciplinedDesign/>
            <TheSystem/>
        </ContentsPage>
    );
}

export default EightPtGrid;