import {useRef} from "react";
import ContentsPage from "../../Components/Contents/contentsPage";
import {StyledHeading, StyledParagraph} from "../../Components/text";

const Choices = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Frontend vs Backend: Which to Develop First?
            </StyledHeading>
            <StyledParagraph>
                I initially focused on frontend development, creating UI prototypes to share with my co-founders.
                This approach helped build rapport and trust early on, as they could visibly see progress.
                One common challenge I've noticed between engineers and non-engineers is a mismatch in expectations
                regarding what is technically achievable within a specific timeframe.
            </StyledParagraph>
            <StyledParagraph>
                By generating UI mockups early on, I set clear expectations and actively involved my co-founders in design decisions, soliciting their input.
                This frontend-first approach not only built trust within the team but also helped materialize our collective vision.
            </StyledParagraph>
            <StyledParagraph>
                The backend development was largely a solo endeavor, where I took full responsibility for designing and implementing the web server.
                My tasks included crafting a relational database to serve both the main server and the recommendation engine,
                identifying the data needed for recommendations, and architecting a monolithic system to manage requests from users and clients.
                A significant portion of the development time was devoted to backend construction.
            </StyledParagraph>
            <StyledParagraph>
                Engineers frequently focus on backend development first, considering it the backbone of the software.
                However, in this case, rapidly delivering a tangible frontend was crucial for building trust.
                Had there been another engineer, we would have started with the backend to accelerate progress.
                I've observed that frontend engineers are generally more common, while backend engineers are comparatively rare,
                a point underscored when only frontend developers joined our project in its later stages.
            </StyledParagraph>
        </div>
    );
};


const DomainDrivenDesign = () => {
    return (
        <div className={'block'}>
            <StyledHeading>
                Domain driven design and its applications
            </StyledHeading>
            <StyledParagraph>
                One of the challenges in backend engineering is the freedom that arises from using HTTP server frameworks. While request handlers are a given, you have the flexibility to structure your code as you wish.
                Although opinionated frameworks like Nest.js exist, they may lead to over-engineering, particularly for simple CRUD APIs.
            </StyledParagraph>
            <StyledParagraph>
                This freedom became an issue when I had to design and create the payment and order processing module. Due to the nature of the module, it became difficult
                to come up with a clean solution. I needed to integrate the Stripe API into the module
                and make sure the code was testable so that future engineers could work on it and improve it.
            </StyledParagraph>
            <StyledParagraph>
                Eventually, I began exploring more effective methodologies for designing software systems. During this search, I came
                across Domain Driven Design as an approach for software development. The approach focuses on designing before implementing and has an important mantra that
                can be applied throughout the software development lifecycle. The approach emphasizes integrating domain-specific language into the system's design,
                thereby actively involving stakeholders in shaping the software. It allows engineers to understand a system from the domain experts' perspective. I applied this process throughout my time at FLY,
                as I would involve my co-founders in technical discussions by explaining problems in an approachable manner.
            </StyledParagraph>
            <StyledParagraph>
                The second part of domain driven design is using the insight and language from discussions to model a software system. The main concepts of the system will commonly crop up in discussions
                and will be used as a foundation for the rest of the system. The classes in DDD that model the main concepts are called <b>Domain models</b> and are typically encapsulated into <b>Aggregate</b> classes
                that act as the interface for a collection of domain objects. <b>Service</b> classes are stateless classes with static methods that manipulate input and return the result,
                much like the functional programming paradigm. An example of the interaction between an Aggregate class and a Service in my design was an unprocessed invoice which served as the aggregate as
                it contained reference to all the items that a user wanted to purchase. The class exposed a method to identify out-of-stock items,
                the output of which is then fed into a service responsible for calculating the total refund due to the user. The other layers that support the system are <b>Factories</b> which handle complex
                object creation and <b>Repositories</b> which interact with persistent data stores.
            </StyledParagraph>
            <StyledParagraph>
                The Domain-Driven Design (DDD) approach enhances code quality by emphasizing thoughtful system design prior to coding.
                This clear roadmap minimizes the need for code rewrites, as you can easily adjust the design if something is amiss, rather than revising the code itself.
            </StyledParagraph>
        </div>
    );
};


const Outsourcing = () => {
   return (
       <div className={'block'} >
           <StyledHeading>
               The problem with outsourcing
           </StyledHeading>
           <StyledParagraph>
               We outsourced a portion of the recommender system to a remote third-party development firm.
               Through regular meetings and demonstrations, everything initially appeared to be on track.
               However, upon reviewing the final codebase, I found it at odds with the coding standards I had set for our internal team.
               The third party system had very poor code hygiene and contained a lot of textbook code smells that needed to be fixed.
           </StyledParagraph>
           <StyledParagraph>
               In retrospect, as a startup, outsourcing our recommender system was a misstep.
               The cultural gap led to a product that fell short of our high standards.
               Coupled with subpar coding practices, this resulted in more time spent in meetings
               and correcting issues post-refactoring than if we had initially developed a simpler solution
               in-house and scaled it according to customer needs.
           </StyledParagraph>
       </div>
   );
};

const FlyReflections = () => {

    const sections = [
        {id: 'choices',text: 'Frontend vs backend', link: '#choices'},
        {id: 'driven', text: 'Domain driven design', link: '#driven'},
        {id: 'design', text: 'The problem with outsourcing', link: '#design'}
    ];

    const choicesRef = useRef(null);
    const dddRef = useRef(null);
    const designRef = useRef(null);

    const refs = [choicesRef, dddRef, designRef];

    return (
        <ContentsPage
            pageTitle={'Lessons from fly'}
            pageSubtitle={'Considerations for future projects'}
            pageDate={'01 Aug 2023'}
            pageSections={sections}
            refs={refs}
        >
            <Choices/>
            <DomainDrivenDesign/>
            <Outsourcing/>
        </ContentsPage>
    );
}

export default FlyReflections;