import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const HomeContentTile = ({tile}) => {
    const {id, title, subtitle, link} = tile;

    const [clicked, setClicked] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        setClicked(true);
    }

    useEffect(() => {
        if (clicked){
            navigate(link);
        }
    }, [clicked, link, navigate]);

    return (
        <div
            onClick={handleClick}
            key={id}
            className={'flex flex-col items-start py-4 px-8 rounded-3xl border-4 border-white shadow-xl shadow-gray-800 cursor-pointer transition-transform transform hover:scale-105'}>
            <p className={'text-white text-4xl font-bold my-8'}>
                {title}
            </p>
            <p className={'text-white text-xl my-4'}>
                {subtitle}
            </p>
        </div>
    );
}

export default HomeContentTile;