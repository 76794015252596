import {useRef, useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";

const NavMenu = ({links, onSelected, selected}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef(null);
    const open = Boolean(anchorEl);

    const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

    const handleMenuItemClick = (id) => {
        setAnchorEl(null);
        onSelected(id);
    }

    const handleClose = () => setAnchorEl(false);

    return (
        <div>
            <Button
                onClick={handleOpenMenu}
                aria-label={'Open menu'}
                endIcon={<MenuIcon/>}
                ref={buttonRef}
                sx={{
                    color: 'white',
                    border: '1px solid white',
                    padding: '0.5rem 1rem'
                }}
            >
                {selected}
            </Button>
            <Menu
                id='basic-menu'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                sx={{
                    '& .MuiMenu-paper': {
                        width: buttonRef.current ? buttonRef.current.offsetWidth : 100,
                        backgroundImage: 'linear-gradient(60deg, #29323c 0%, #485563 100%);',
                        margin: '0.5rem 0',
                        border: '1px solid white',
                    }
                }}
            >
                {
                    links.map(item =>
                        <MenuItem
                            onClick={() => handleMenuItemClick(item.id)}
                            selected={item.id === selected}
                            sx={{
                                padding: 0
                            }}
                        >
                            <NavLink
                                to={item.link}
                                className={'text-white text-lg py-4 px-4 w-full h-full'}
                            >
                                {item.text}
                            </NavLink>
                        </MenuItem>
                    )
                }
            </Menu>
        </div>
    );

}

/*

 */

export default NavMenu;