import {Outlet} from "react-router-dom";
import {PageWrapper} from "../../Components/containers";
const Thoughts = () => {

    return (
        <PageWrapper>
            <Outlet/>
        </PageWrapper>
    );
}

export default Thoughts;