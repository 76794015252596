import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Root from "../root";
import Projects from "../Pages/Projects/projects";
import Thoughts from "../Pages/Thoughts/thoughts";
import Home from "../Pages/Home/home";
import About from "../Pages/About/about";
import FlySenga from "../Pages/Projects/flySenga";
import SaleoDashboard from "../Pages/Projects/saleoDashboard";
import RecentProjects from "../Pages/Projects/recentProjects";
import RecentThoughts from "../Pages/Thoughts/recentThoughts";
import FlyReflections from "../Pages/Thoughts/flyReflections";
import EightPtGrid from "../Pages/Thoughts/eightPtGrid";
import ProtectingEndpoints from "../Pages/Thoughts/protectingEndpoints";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<Root/>}>
            <Route path={'about'} element={<About/>}/>
            <Route path={'projects'} element={<Projects/>}>
                <Route path={'fly-senga'} element={<FlySenga/>}/>
                <Route path={'saleo-dashboard'} element={<SaleoDashboard/>}>
                </Route>
                <Route index element={<RecentProjects/>}/>
            </Route>
            <Route path={'thoughts'} element={<Thoughts/>}>
                <Route path={'lessons-from-fly'} element={<FlyReflections/>}/>
                <Route path={'8-pt-grid'} element={<EightPtGrid/>}/>
                <Route path={'protecting-endpoints'} element={<ProtectingEndpoints/>}/>
                <Route index element={<RecentThoughts/>}/>
            </Route>
            <Route index element={<Home/>}/>
        </Route>
    ));

export default router;